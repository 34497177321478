<template>
  <div class="welcomeMain">
    <div class="welcomeTop">
      <h1>BIENVENIDO</h1>
    </div>
    <hr />
    <div class="welcomeBottom">
      <p>
        Bienvenido a la familia Assoft, ahora podrá gestionar los accidentes,
        enfermedades y perfiles sociodemográficos de sus empleados, además de
        los seguimientos documentales que exige la ley vigente.
      </p>
      <router-link to="/loading">
        <button type="button" class="btn btn-outline-primary">Comenzar</button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  mounted() {
    Swal.fire({
      icon: "success",
      title: "Cuenta creada exitosamente",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });
  }
};
</script>

<style lang="css">
.welcomeTop {
  width: 100vw;
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.welcomeTop h1 {
  text-align: center;
  font-size: 5.5em;
  animation-name: title;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}
.welcomeMain hr {
  width: 30%;
  margin: auto;
}
.welcomeBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcomeBottom p {
  text-align: center;
  margin-top: 20px;
  padding: 0 30px;
  animation-name: par;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
}
.welcomeBottom button {
  color: var(--bs-primary);
  animation-name: button;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
}
@media (max-width: 600px) {
  .welcomeTop h1 {
    font-size: 3.5em;
  }
}
@keyframes title {
  from {
    margin-bottom: 50px;
    opacity: 0;
  }
  to {
    margin-bottom: 8px;
    opacity: 1;
  }
}
@keyframes par {
  0% {
    margin-top: 60px;
    opacity: 0;
  }
  50% {
    margin-top: 60px;
    opacity: 0;
  }
  100% {
    margin-top: 20px;
    opacity: 1;
  }
}
@keyframes button {
  0% {
    transform: rotate(15deg);
    opacity: 0;
  }
  75% {
    transform: rotate(15deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
</style>
